<template>
    <Toast />
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <h5>Tax Invoices</h5>
                <DataTable :value="billlinginvoicelist" :lazy="true" :paginator="true" :rows="30"
                    :totalRecords="totalRecords" :loading="loading" @page="onPage($event)" class="p-datatable-users"
                    data-key="bl1" :rowHover="true"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
                    <Toolbar>
                        <template v-slot:left>
                            <h5 class="p-m-0">Total Amount : <span class="indigo-color"> {{
                                formatCurrency(this.totalTaxAmount ? this.totalTaxAmount : '0') }}</span>
                            </h5>
                        </template>
                        <template v-slot:right>
                            <Button label="Filter" icon="pi pi-filter" class=" p-mr-2" @click="clientFilterDialogOpen" />
                        </template>
                    </Toolbar>
                    <template #empty> No data found. </template>
                    <template #loading> Loading data Please wait... </template>
                    <Column field="invoicedate" header="Date" headerStyle="width: 10%">
                        <template #body="{ data }">
                            <div>{{ format_timestamp(data.bl6) }}</div>
                        </template>
                    </Column>
                    <Column header="Admin Name / Invoice No" headerStyle="width: 40%">
                        <template #body="{ data }">
                            <div>
                                <div class="p-text-capitalize"><span v-tooltip.right="'Admin Name'">{{ data.bl3 }}</span>
                                </div>

                                <small class="muted-text p-text-capitalize p-d-flex p-my-1"><span
                                        v-tooltip.right="'Invoice No.'">{{ data.bl5 }}</span></small>
                            </div>
                        </template>
                    </Column>
                    <Column header="Invoice Amount" headerStyle="width: 13%">
                        <template #body="{ data }">
                            <div class="muted-text">{{ formatCurrency(data.bl7 ? data.bl7 : '0') }}</div>

                        </template>
                    </Column>
                    <Column header="Tax Amount" headerStyle="width: 13%">
                        <template #body="{ data }">
                            <div class="muted-text"> {{ formatCurrency(data.bl8 ? data.bl8 : '0') }} </div>

                        </template>
                    </Column>
                    <Column header="Total Amount" headerStyle="width: 13%">
                        <template #body="{ data }">
                            <div> {{ formatCurrency(data.bl9 ? data.bl9 : '0') }} </div>

                        </template>
                    </Column>
                    <Column header="Action" headerStyle="width: 12%">
                        <template #body="{ data }">

                            <a v-if="data.bl11 != '' && data.bl11 != null" class="download-btn" target="_blank"
                                :href="data.bl11" v-tooltip.left="'Download Invoice'"><i class="pi pi-download"></i></a>
                            <Button icon="pi pi-eye" v-tooltip.left="'View Details'"
                                class="p-button-rounded p-button-success p-ml-2" style="margin: right 2.5rem;"
                                @click="viewDetailDialogOpen(data)" />

                            <!-- <Button icon="pi pi-download" v-tooltip.left="'Download Invoice'" class="p-button-rounded" /> -->
                        </template>
                    </Column>
                </DataTable>
                <Toast />
            </div>
        </div>
    </div>
    <!-- Filter dialog start here -->
    <Dialog v-model:visible="filterclientStatus" :style="{ width: '600px' }" header="Filter" :modal="true" class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-6">
                    <label for="fromdate">From Date</label>
                    <Calendar :showIcon="true" :monthNavigator="true" :yearNavigator="true" v-model="fromdate"
                        :maxDate="todaysDate" placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy"
                        :yearRange="'2020:' + new Date().getFullYear()" appendTo="body" required="true" :manualInput="false"
                        :class="{ 'p-invalid': submitted && !fromdate }"></Calendar>
                </div>
                <div class="p-field p-col-6">
                    <label for="todate">To Date</label>
                    <Calendar :showIcon="true" :monthNavigator="true" :yearNavigator="true" v-model="todate"
                        :minDate="fromdate" :maxDate="todaysDate" placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy"
                        :yearRange="'2020:' + new Date().getFullYear()" appendTo="body" required="true" :manualInput="false"
                        :class="{ 'p-invalid': submitted && !todate }"></Calendar>
                </div>
                <div class="p-field p-col-12 p-md-12">
                    <label for="clientname">
                        Select Admin
                    </label>
                    <Dropdown id="clientname" v-model="adminname" :options="adminList" optionLabel="label"
                        placeholder="Select ..." appendTo="body"></Dropdown>
                </div>
            </div>
        </div>
        <template #footer>
            <div>
                <Button v-if="!showLoader" label="Search" icon="pi pi-search" class="p-button-text p-ml-auto"
                    @click="filterAdmin(fromdate, todate, adminname)" style="width: 90px"></Button>

                <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                    style="width: 90px"></Button>
            </div>
        </template>
    </Dialog>
    <!-- Filter dialog end here -->
    <!-- View Details dialog start here -->
    <Dialog header="Tax Invoice Component" v-model:visible="viewcomponentdetailStatus" :style="{ width: '75vw' }"
        :maximizable="true" :modal="true" :contentStyle="{ height: '400px' }">
        <DataTable :value="taxComponetslist" :scrollable="true" scrollHeight="flex" :lazy="true"
            :totalRecords="totalRecords" :loading="loading" @page="onPage($event)" class="p-datatable-users" data-key="bd1"
            :rowHover="true">

            <template #empty> No data found. </template>
            <template #loading> Loading data Please wait... </template>
            <Column header="Component Name" style="min-width:300px">
                <template #body="{ data }">

                    <div class="p-text-capitalize"> {{ data.bm4 }}</div>
                </template>
            </Column>
            <Column header="Extra Usage" style="min-width:150px">
                <template #body="{ data }">
                    <div> {{ data.bm6 }} * {{ data.bm5 }}</div>
                </template>
            </Column>
            <Column header="Extra Usage Amount" style="min-width:150px" class="p-text-right">
                <template #body="{ data }">
                    <div>&#8377; {{ data.bm7 }}</div>
                </template>
            </Column>
            <Column header="CGST" style="min-width:100px">
                <template #body="{ data }">
                    <div>&#8377; {{ data.bm11 }}</div>
                </template>
            </Column>
            <Column header="SGST" style="min-width:100px">
                <template #body="{ data }">
                    <div>&#8377; {{ data.bm13 }}</div>
                </template>
            </Column>
            <Column header="Total Amount" style="min-width:100px">
                <template #body="{ data }">
                    <div>&#8377; {{ data.bm17 }}</div>
                </template>
            </Column>
        </DataTable>
    </Dialog>
    <!-- View Details dialog end here -->
</template>
<script>
import moment from "moment";
import ApiService from '../service/ApiService';
export default {
    data() {
        return {
            billlinginvoicelist: [],
            page_no: 0,
            totalRecords: 0,
            loading: false,
            filterclientStatus: false,
            fromdate: '',
            todate: '',
            adminname: '',
            adminList: [],
            showLoader: false,
            todaysDate: new Date(),
            viewcomponentdetailStatus: false,
            totalbillamount: 0,
            submitted: false,
            taxComponetslist: [],
            totalTaxAmount: 0,
            itemsPerPage: 30
        };
    },
    ApiService: null,
    interval: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        this.getTaxInvoices({ clientname: this.adminname, fromdate: this.fromdate, todate: this.todate, page_no: this.page_no, itemsPerPage: this.itemsPerPage });
    },
    methods: {
        getTaxInvoices(ev) {
            this.loading = true;
            this.ApiService.getTaxInvoices(ev).then((data) => {
                if (data.status == 200) {
                    this.billlinginvoicelist = data.data;
                    this.totalRecords = data.count;
                    if (data.totalTaxAmount)
                        this.totalTaxAmount = data.totalTaxAmount;
                    this.loading = false;
                } else {
                    this.billlinginvoicelist = '';
                    this.totalRecords = 0;
                    this.totalTaxAmount = 0;
                    this.loading = false;
                }
            });
        },
        onPage(event) {
            this.loading = true;
            this.page_no = event.page;
            this.getTaxInvoices({ page_no: event.page, fromdate: this.fromdate, todate: this.todate, clientname: this.adminname, itemsPerPage: this.itemsPerPage });

        },
        onlyNumber($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
                $event.preventDefault();
            }
        },
        filterAdmin(fromdate, todate, adminname) {
            this.filterclientStatus = false;
            this.adminname = adminname;
            if (fromdate != "" && fromdate != null) {
                var from_date = moment(fromdate).format("YYYY-MM-DD");
            }
            if (todate != "" && todate != null) {
                var to_date = moment(todate).format("YYYY-MM-DD");
            }
            this.getTaxInvoices({ page_no: this.page_no, clientname: this.adminname, fromdate: from_date, todate: to_date, itemsPerPage: this.itemsPerPage });
        },

        clientFilterDialogOpen() {
            this.filterclientStatus = true;
            this.getAdminListOnly();
        },
        getAdminListOnly(e) {
            this.ApiService.getAdminListOnly(e).then((data) => {
                if (data.status == 200) {
                    this.adminList = data.data.records;
                } else {
                    this.adminList = '';
                }
            });
        },

        viewDetailDialogOpen(e) {
            this.viewcomponentdetailStatus = true;
            this.ApiService.getTaxInvoiceComponent({ invoiceId: e.bl1 }).then((data) => {
                if (data.status == 200) {
                    this.taxComponetslist = data.data;
                    this.totalRecords = data.count;
                    this.loading = false;
                } else {
                    this.taxComponetslist = '';
                    this.totalRecords = 0;
                    this.loading = false;
                }

            });

        },
        formatCurrency(amount, currency = 'INR', minimumFractionDigits = 2) {
            let formatterAmount = new Intl.NumberFormat('en-IN', { minimumFractionDigits }).format(amount.toString());
            return (+0).toLocaleString('en-IN', { style: 'currency', currency })
                .replace('0.00', " " + formatterAmount)
        },
        format_timestamp(value) {
            if (value) {
                var localTime = new Date(value);
                return moment(localTime).format("DD/MM/YYYY");
            } else {
                return 'N/A';
            }
        },
    },
};
</script>
<style scoped lang="scss">
.download-btn {
    padding: 10px;
    border-radius: 50%;
    height: 2.75rem;
    color: #ffffff;
    background: #3c6895;
    border: 0 none;
    width: 38.5px;
    display: inline-block;
}
</style>
